import React from "react";
import styled from "styled-components"

import { AuthService, useAuth } from "gatsby-theme-auth0";

const AuthButton = styled.button`
  color: green;
`

export default () => {
  const { isLoggedIn, profile } = useAuth();
  return (
    <div>
      {profile && <p>Hello {profile.name}</p>}
      {
        isLoggedIn ? ( <AuthButton onClick={AuthService.logout}>Logout</AuthButton> ) : 
          ( <AuthButton onClick={AuthService.login}>Login</AuthButton> )
      }
    </div>
  );
};
