import React from "react";
import styled from "styled-components";
import { Container } from "../components/global";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo"

import { AuthService, useAuth } from "gatsby-theme-auth0";

import Junk from "../components/common/auth/loginbutton";
import { Chargebee, Buy} from "../components/common/chargebee";

const IndexPage = () => {
    const { isLoading, isLoggedIn, profile } = useAuth();

    return (
          <Layout>
          <SEO title="GPOAE" />
          <Chargebee />
            <MyContainer>

                <JunkButton />
                buy
                <Buy plan="basic" text="Baaasic!" />
                <Buy plan="basic_12" text="Baaasic Annual" />
                <Buy plan="standard" text="Stttttaaandard" />

                <br />
                <button onClick={ (e) => { testclick(isLoading) } }>isLoading</button>
                <button onClick={ (e) => { testclick(isLoggedIn) } }>isLoggedIn</button>
                <button onClick={ (e) => { testclick(profile) } }>profile</button>
                <br/>
                <button onClick={ (e) => { testclick(AuthService.getAccessToken()) } }>getAccessToken</button>
                <button onClick={ (e) => { testclick(AuthService.getIdToken()) } }>getIdToken</button>
                <button onClick={ (e) => { testclick(AuthService.getUserProfile()) } }>getUserProfile</button>
                <button onClick={ (e) => { testclick(AuthService.checkSession()) } }>checkSession</button>
                <button onClick={ (e) => { testclick(process.env.AUTH0_DOMAIN) } }>domain</button>
                <code>
                    <pre>
                    Stuff <br/>
                        isLoading: { isLoading ? "True" : "False" }<br/>
                        isLoggedIn: { isLoggedIn ? "True" : "False" }<br/>
                        
                        { profile && <p>Hello {profile.name}</p> }
                    </pre>
                </code>
            </MyContainer>
          </Layout>
)}

const testclick = (value) =>{
    console.log("Hi: ");
    console.log(value);
}

const MyContainer = styled(Container)`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
`

const JunkButton = styled(Junk)`
    align-self: center;
`

export default IndexPage
